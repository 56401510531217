import {
  getPrices,
  getPricesByCustomer,
  getStocks,
  TipoDeDocumento,
} from "@suttyweb/hyperpop";
import { precioEsValido, warehouseCode, calcularStockVendible } from "./utils";

document.addEventListener("turbo:load", () => {
  if (!window.headers) return;

  const productEls = document.querySelectorAll("[data-catalogo-product-sku]");
  if (productEls.length === 0) return;
  else if (
    !localStorage.customerPriceListNumber ||
    localStorage.customerPriceListNumber.length === 0
  ) {
    // Forzar iniciar sesión
    Turbo.visit("/");
    return;
  }

  const cargandoEl = document.querySelector("#cargando");
  const productosEl = document.querySelector("#productos");

  const { customerPriceListNumber: priceListNumber } = localStorage;
  return Promise.all([
    getPrices(process.env.TANGO_HOST, window.headers, {
      filter: priceListNumber,
    }),
    getStocks(process.env.TANGO_HOST, window.headers, {
      warehouseCode,
    }),
  ])
    .then(([precios, stocks]) => {
      const preciosFiltrados = precios.Data.filter((precio) =>
        precioEsValido(priceListNumber, precio)
      );
      for (const productEl of productEls) {
        let unidadesPorPallet = parseInt(productEl.dataset.unidadesPorPallet);
        if (isNaN(unidadesPorPallet)) unidadesPorPallet = 1;

        const sku = productEl.dataset.catalogoProductSku;
        const precio = preciosFiltrados.find(
          (precio) => precio.SKUCode === sku
        );
        const stockObj = stocks.Data.find(
          (stockObj) => stockObj.SKUCode === sku
        );
        const stock = stockObj && calcularStockVendible(stockObj, unidadesPorPallet);
        if (!precio || !stockObj || stock < 1) {
          productEl.remove();
        }
      }
      cargandoEl.hidden = true;
      productosEl.hidden = false;
      // TODO: mostrar una pantalla cuando no hay ningún producto
    })
    .catch((error) => {
      console.error(
        `Error consiguiendo precios de la lista ${priceListNumber}:`,
        error
      );
      window.dispatchEvent(
        new CustomEvent("notification", {
          detail: {
            template: "alert",
            data: { type: "warning", content: site.i18n.alerts.error },
          },
        })
      );
    });
});
