import { updateCartPage } from "./cart-page";

export function getCart() {
  return JSON.parse(localStorage.cart || "[]");
}
export function updateCart(cart) {
  localStorage.cart = JSON.stringify(cart);
  updateCartCounter();
  updateCartPage();
}

function updateCartCounter() {
  const cartCounterEl = document.querySelector("#cart-counter");
  cartCounterEl.textContent = getCart().length;
}

document.addEventListener("turbo:load", () => {
  updateCartCounter();
});
