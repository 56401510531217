import { Controller } from "stimulus";
import { Liquid } from "liquidjs";

/*
 * Waits for notifications and shows them by fetching and rendering
 * a template.
 */
export default class extends Controller {
  connect() {
    window.addEventListener(
      "notification",
      async (event) =>
        await this.render(event.detail.template, event.detail.data)
    );
  }

  /*
   * Renders and replaces notification contents and then shows it.  Does
   * nothing if the template isn't found.
   */
  async render(name, data = {}) {
    data.site = window.site;

    const template = window.templates.alert;
    const html = await this.engine.parseAndRender(template, data);

    this.element.innerHTML = html;
    this.show();
  }

  /*
   * Shows the notification
   */
  show() {
    this.element.classList.add("show");
    this.element.classList.remove("hide");
  }

  /*
   * Hides the notification
   */
  hide() {
    this.element.classList.add("hide");
    this.element.classList.remove("show");
  }

  /*
   * Liquid renderer
   *
   * @return Liquid
   */
  get engine() {
    if (!window.liquid) window.liquid = new Liquid();

    return window.liquid;
  }
}
