import { getCustomers, TipoDeDocumento } from "@suttyweb/hyperpop";
import * as Turbo from "@hotwired/turbo";

document.addEventListener("turbo:load", () => {
  const loginForm = document.querySelector("#login-form");
  if (loginForm) {
    loginForm.addEventListener("submit", (event) => {
      event.preventDefault();

      const fieldsetEl = event.target.querySelector("fieldset");
      fieldsetEl.disabled = true;

      const cuitCrudo = event.target.cuit.value;
      const cuitSinGuiones = cuitCrudo.match(/(\d{2})(\d{8})(\d)/);

      const cuit = cuitSinGuiones
        ? `${cuitSinGuiones[1]}-${cuitSinGuiones[2]}-${cuitSinGuiones[3]}`
        : cuitCrudo;

      const basic = btoa(`${cuit.replaceAll("-", "")}:${event.target.pass.value}`);
      document.cookie = `authorization=Basic ${basic};max-age=${60 * 60 * 8};samesite=strict;secure;`;
      window.sessionStorage.authorization = `Basic ${basic}`;

      window.headers = new Headers();
      window.headers.set("authorization", window.sessionStorage.authorization);
      window.headers.set("content-type", "application/json");

      getCustomers(process.env.TANGO_HOST, window.headers, {
        customer: {
          type: TipoDeDocumento.CUIT,
          number: cuit,
        },
      })
        .then((customers) => {
          if (customers.Data.length !== 1) {
            throw new Error("Encontré más de unx cliente.");
          }
          const customer = customers.Data[0];
          localStorage.customerJson = JSON.stringify(customer);
          localStorage.customerCuit = cuit;
          localStorage.customerPriceListNumber = customer.PriceListNumber;

          Turbo.visit("/catalogo/");
        })
        .catch((error) => {
          console.error(
            `Hubo un error iniciando sesión con el CUIT ${cuit}:`,
            error
          );
          window.dispatchEvent(
            new CustomEvent("notification", {
              detail: {
                template: "alert",
                data: { type: "danger", content: site.i18n.alerts.cuit_error },
              },
            })
          );
        })
        .finally(() => (fieldsetEl.disabled = false));

      return false;
    });
  }
});
