import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    if (!window.headers) return;
    if (this.hasButtonTarget) this.buttonTarget.hidden = false;
  }

  logout (event = undefined) {
    event?.preventDefault();

    window.headers = undefined;
    document.cookie = "authorization=;expires=Thu, 01 Jan 1970 00:00:00 GMT;samesite=strict;secure";
    window.sessionStorage.clear();

    Turbo.visit("/");
  }
}
