import { getCart, updateCart } from "./cart";
import { getPrices, order } from "@suttyweb/hyperpop";
import { precioEsValido } from "./utils";

document.addEventListener("turbo:load", () => {
  if (!window.headers) return;

  const productEls = document.querySelectorAll("[data-cart-product-sku]");
  if (
    productEls.length !== 0 &&
    !(
      localStorage.customerPriceListNumber &&
      localStorage.customerPriceListNumber.length > 0
    )
  ) {
    // Forzar iniciar sesión
    Turbo.visit("/");
    return;
  }

  for (const productEl of productEls) {
    const sku = productEl.dataset.cartProductSku;

    const quantityEl = productEl.querySelector("input[name=quantity]");
    quantityEl.addEventListener("input", () => {
      const cart = getCart();
      updateCart(
        cart.map((product) =>
          product.sku === sku
            ? { sku, quantity: parseInt(quantityEl.value) }
            : product
        )
      );
    });

    const removeBtnEl = productEl.querySelector("button[name=remove]");
    removeBtnEl.addEventListener("click", () => {
      const cart = getCart();
      updateCart(cart.filter((product) => product.sku !== sku));
    });
  }

  const hacerPedidoEl = document.querySelector("#hacer-pedido");
  if (!hacerPedidoEl) return;
  hacerPedidoEl.addEventListener("click", (event) => {
    const cart = getCart();

    // Se supone que Total es:
    // >=0 ∑[(OrderItems.Quantity x OrderItems.UnitPrice) – OrderItems.DiscountPorcentage)] + Shipping.ShippingCost + Principal.FinancialSurcharge – Principal.TotalDiscount
    // Pero no tenemos la mayoría de las variables.
    const products = cart.map((product) => {
      const productEl = document.querySelector(
        `[data-cart-product-sku="${product.sku}"]`
      );
      const unitPrice = parseFloat(productEl.dataset.cartProductPrice);
      const unidadesPorCaja = parseInt(
        productEl.dataset.cartProductUnidadesPorCaja
      );
      const cajasPorPallet = parseInt(
        productEl.dataset.cartProductCajasPorPallet
      );

      return { ...product, unitPrice, unidadesPorCaja, cajasPorPallet };
    });
    // Multiplicamos y después dividimos para que los decimales sean precisos.
    const total =
      products.reduce(
        (total, curr) =>
          total +
          curr.unitPrice *
            Math.pow(10, 2) *
            (curr.quantity * curr.unidadesPorCaja * curr.cajasPorPallet),
        0
      ) / Math.pow(10, 2);

    // Se supone que es único ¡pero lo tenemos que generar nosotrxs! wtf
    const id = Math.floor(Math.random() * 100000).toString();

    const customer = JSON.parse(localStorage.customerJson);

    if (customer.BusinessName === "PRUEBA") {
      customer.Email = "sutty@riseup.net";
    }

    const pedido = {
      Date: new Date().toISOString(),
      Total: total,
      OrderID: id,
      OrderNumber: id,
      OrderItems: products.map((product) => ({
        Description: product.sku,
        UnitPrice: product.unitPrice,
        Quantity:
          product.quantity * product.unidadesPorCaja * product.cajasPorPallet,
        ProductCode: product.sku,
        SKUCode: product.sku,
        SelectMeasureUnit: "P",
      })),
      Customer: {
        CustomerID: 1,
        User: customer.BusinessName,
        IVACategoryCode: customer.IvaCategoryCode,
        Email: customer.Email,
        ProvinceCode: customer.ProvinceCode,
        DocumentNumber: customer.DocumentNumber,
        DocumentType: customer.DocumentType,
      },
    };

    order(process.env.TANGO_HOST, window.headers, pedido)
      .then(() => {
        updateCart([]);
        Turbo.visit("/confirmacion/");
      })
      .catch((error) => {
        console.error(
          "Hubo un error haciendo el pedido",
          pedido,
          "con el carrito",
          cart,
          ":",
          error
        );
        window.dispatchEvent(
          new CustomEvent("notification", {
            detail: {
              template: "alert",
              data: { type: "danger", content: site.i18n.alerts.error },
            },
          })
        );
      });
  });

  updateCartPage();

  getProductPrices();
});

export function updateCartPage() {
  updateCartItems();
  updateCartPriceCounters();
}

// Conseguir precios de los productos de la API, ponerlos en el DOM y refrescar precios (via updateCartPriceCounters)
function getProductPrices() {
  const productEls = document.querySelectorAll("[data-cart-product-sku]");

  const { customerPriceListNumber: priceListNumber } = localStorage;
  return getPrices(process.env.TANGO_HOST, window.headers, {
    filter: priceListNumber,
  })
    .then((precios) => {
      const preciosFiltrados = precios.Data.filter((precio) =>
        precioEsValido(priceListNumber, precio)
      );
      for (const productEl of productEls) {
        const sku = productEl.dataset.cartProductSku;
        const precio = preciosFiltrados.find(
          (precio) => precio.SKUCode === sku
        );
        if (!precio) {
          const unitPriceEl = productEl.querySelector(
            "[data-cart-product-unit-price]"
          );
          unitPriceEl.textContent = "No encontré este producto en la tienda.";
          continue;
        }
        productEl.dataset.cartProductPrice = precio.Price;
      }
      updateCartPriceCounters();
    })
    .catch((error) => {
      console.error(
        `Error consiguiendo precios de la lista ${priceListNumber}:`,
        error
      );
      window.dispatchEvent(
        new CustomEvent("notification", {
          detail: {
            template: "alert",
            data: { type: "warning", content: site.i18n.alerts.error },
          },
        })
      );
    });
}

// Mostrar o ocultar productos dependiendo de si están en el carrito.
function updateCartItems() {
  const cart = getCart();
  for (const productEl of document.querySelectorAll(
    "[data-cart-product-sku]"
  )) {
    const sku = productEl.dataset.cartProductSku;
    const productInCart = cart.find((product) => product.sku === sku);

    if (productInCart) {
      const quantityEl = productEl.querySelector("input[name=quantity]");
      quantityEl.value = productInCart.quantity;
    }

    productEl.hidden = !productInCart;
  }
}

// Recalcular precio por unidad y subtotal para todos los productos, y a partir de eso calcular el total.
function updateCartPriceCounters() {
  const cart = getCart();
  let cartTotalPrice = 0;
  let cartTotalPriceTainted = false;
  for (const productEl of document.querySelectorAll(
    "[data-cart-product-sku]"
  )) {
    const sku = productEl.dataset.cartProductSku;
    const productInCart = cart.find((product) => product.sku === sku);
    if (!productInCart) continue;

    const unitPriceEl = productEl.querySelector(
      "[data-cart-product-unit-price]"
    );
    const totalPriceEl = productEl.querySelector(
      "[data-cart-product-total-price]"
    );

    const price = parseFloat(productEl.dataset.cartProductPrice);
    if (isNaN(price)) {
      cartTotalPriceTainted = true;
      continue;
    }
    if (unitPriceEl) unitPriceEl.textContent = price.toFixed(2);

    try {
      const unidadesPorCaja = parseInt(
        productEl.dataset.cartProductUnidadesPorCaja
      );
      const cajasPorPallet = parseInt(
        productEl.dataset.cartProductCajasPorPallet
      );
      if (isNaN(cajasPorPallet) || isNaN(unidadesPorCaja)) {
        if (totalPriceEl)
          totalPriceEl.textContent =
            "falta la información de caja por pallet o unidades por caja para calcular";
        cartTotalPriceTainted = true;
        throw new Error("Falta unidadesPorCaja/cajasPorPallet");
      }

      const totalPrice =
        price * productInCart.quantity * unidadesPorCaja * cajasPorPallet;
      if (totalPriceEl) totalPriceEl.textContent = totalPrice.toFixed(2);

      cartTotalPrice += totalPrice;
    } catch (err) {
      console.warn("No pude setear el precio de", productEl, "por", err);
    }
  }
  const cartTotalPriceEl = document.querySelector("[data-cart-total-price]");
  if (cartTotalPriceEl) {
    cartTotalPriceEl.textContent = cartTotalPrice.toFixed(2);
    document.querySelector("#hacer-pedido").disabled = cartTotalPriceTainted;
    if (cartTotalPriceTainted) {
      cartTotalPriceEl.textContent +=
        " (el total puede ser incorrecto por los errores anteriores)";
    }
  }
}
