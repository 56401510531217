import { getCart, updateCart } from "../cart";

document.addEventListener("turbo:load", () => {
  /// <form class='add-to-cart-form'>
  ///   <meta itemprop="sku" content="UN SKU">
  ///   <input name="quantity" type="number" min="0" max="{{ include.product.stock }}">
  ///   <!-- o usar _includes/add_quantity.html -->
  ///   <button>Agregar al carrito</button>
  /// </form>
  for (const form of document.querySelectorAll(".add-to-cart-form")) {
    form.addEventListener("submit", (event) => {
      event.preventDefault();

      // no agregar productos sin stock al carrito
      if (form.quantity.disabled) return;

      const skuMetaEl = form.querySelector('meta[itemprop="sku"]');
      if (!skuMetaEl) {
        console.error(
          'No hay un <meta itemprop="sku" ..> en este .add-to-cart-form',
          form
        );
        return;
      }
      const sku = skuMetaEl.content;
      const quantity = parseInt(form.quantity.value);

      const cart = getCart();
      const productInCart = cart.find((product) => product.sku === sku);
      if (productInCart) {
        updateCart(
          cart.map((product) =>
            product.sku === sku
              ? { sku, quantity: productInCart.quantity + quantity }
              : product
          )
        );
      } else {
        updateCart([...cart, { sku, quantity: quantity }]);
      }
      window.dispatchEvent(
        new CustomEvent("toast", { detail: { content: "Añadido al carrito." } })
      );
    });
  }
});
