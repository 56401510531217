export const warehouseCode = "01";

export function precioEsValido(priceListNumber, precio) {
  const now = new Date();
  return (
    precio.PriceListNumber === parseInt(priceListNumber) &&
    now < new Date(precio.ValidityDateUntil) &&
    now > new Date(precio.ValidityDateSince)
  );
}

export function calcularStockVendible(stockObj, unidadesPorPallet = 1) {
  const stockVendible = Math.max(0, ((stockObj.Quantity - stockObj.EngagedQuantity) / unidadesPorPallet) - 28);
  return isFinite(stockVendible) ? stockVendible : 0;
}
