import {
  getPrices,
  getPricesByCustomer,
  TipoDeDocumento,
} from "@suttyweb/hyperpop";
import { precioEsValido } from "./utils";

document.addEventListener("turbo:load", () => {
  if (!window.headers) return;

  const priceEls = document.querySelectorAll("[data-price-product-sku]");
  if (
    priceEls.length !== 0 &&
    !(
      localStorage.customerPriceListNumber &&
      localStorage.customerPriceListNumber.length > 0
    )
  ) {
    // Forzar iniciar sesión
    Turbo.visit("/");
    return;
  }
  [...priceEls].map((priceEl) => {
    const sku = priceEl.dataset.priceProductSku;
    const { customerPriceListNumber: priceListNumber } = localStorage;
    return getPrices(process.env.TANGO_HOST, window.headers, {
      SKUCode: sku, // Tango ignora este SKUCode... tenemos que filtrar manualmente después
      filter: priceListNumber,
    })
      .then((precios) => {
        const preciosFiltrados = precios.Data.filter(
          (precio) =>
            precio.SKUCode === sku && precioEsValido(priceListNumber, precio)
        );
        if (preciosFiltrados.length > 1) {
          console.warn(
            `Encontre demasiados precios filtrados para ${sku} en la lista ${priceListNumber}:`,
            preciosFiltrados
          );
        }
        if (preciosFiltrados.length === 0) {
          console.error(`No existe el precio para ${sku}`);
          priceEl.textContent = "no encontré el precio en Tango Tiendas.";
        } else {
          priceEl.textContent = preciosFiltrados[0].Price;
        }
      })
      .catch((error) => {
        console.error(
          `Error consiguiendo precio para ${sku} en la lista ${priceListNumber}:`,
          error
        );
        priceEl.textContent =
          "error consultando Tango Tiendas. Puede que este producto no esté disponible.";
      });
  });
});
