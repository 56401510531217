import BotDetector from "device-detector-js/dist/parsers/bot";
import { Notifier } from "@airbrake/browser";

window.bot_detector = new BotDetector();
const bot = window.bot_detector.parse(navigator.userAgent);

if (!bot && window.env.JEKYLL_ENV === "production") {
  window.airbrake = new Notifier({
    projectId: window.env.AIRBRAKE_PROJECT_ID,
    projectKey: window.env.AIRBRAKE_PROJECT_KEY,
    host: "https://panel.sutty.nl",
  });

  console.originalError = console.error;
  console.error = (...e) => {
    window.airbrake.notify(e.join(" "));
    return console.originalError(...e);
  };
}

import "core-js/stable";
import "regenerator-runtime/runtime";

// Turbo acelera la navegación al no tener que recargar todo el JS y CSS
// de la página, con lo que se siente más rápida y "nativa".
//
// Cambiamos de turbolinks a turbo porque turbo soporta la función
// fetch(), que luego es interceptada por el SW para obtener las
// direcciones localmente.
import * as Turbo from "@hotwired/turbo";
Turbo.start();

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

const authorization = document.cookie.split(";").find(x => x.startsWith("authorization="))?.split("=")[1];

import { dummy } from "@suttyweb/hyperpop";

if (window.sessionStorage.authorization || authorization) {
  window.headers = new Headers();
  window.headers.set("content-type", "application/json");
  window.headers.set("authorization", window.sessionStorage.authorization || authorization);
}

import "./forms/home";
import "./forms/addToCart";
import "./product";
import "./catalogo";
import "./cart-page";
import "./quantityStockLimit";

if (false) {
  try {
    window.axe = require("axe-core/axe");
  } catch (e) {}
}

if (window.axe)
  window.axe.configure({ locale: require("axe-core/locales/es.json") });

document.addEventListener("turbo:load", async (event) => {
  const isRoot = (window.location.pathname == "/");

  if (window.headers && !isRoot) {
    try {
      await dummy(process.env.TANGO_HOST, window.headers);
    } catch (e) {
      document.cookie = "authorization=;expires=Thu, 01 Jan 1970 00:00:00 GMT;samesite=strict;secure";
      window.headers = undefined;
      window.sessionStorage.clear();
    }
  }

  if (!isRoot && !window.headers) Turbo.visit("/");
  if (isRoot && window.headers) Turbo.visit("catalogo/");

  document
    .querySelectorAll("a[href^='http://'],a[href^='https://'],a[href^='//']")
    .forEach((a) => {
      a.rel = "noopener";
      a.target = "_blank";
    });

  if (!window.axe) return;

  window.axe.run().then((results) => {
    results.violations.forEach((violation) => {
      violation.nodes.forEach((node) => {
        node.target.forEach((target) => {
          document.querySelectorAll(target).forEach((element) => {
            element.classList.add("inaccesible");
            element.ariaLabel = node.failureSummary;
          });
        });
      });
    });
  });
});
